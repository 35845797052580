import { Controller } from "@hotwired/stimulus";

import copy from "copy-to-clipboard";

export default class extends Controller {
  static targets = ["copyButton"];
  static values = { text: String, successText: String };

  copy() {
    copy(this.textValue);
    const buttonText = this.copyButtonTarget.innerText;
    this.copyButtonTarget.innerText = this.successTextValue
    setTimeout(() => {
      this.copyButtonTarget.innerText = buttonText;
    }, 1000);
  }
}
